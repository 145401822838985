
export default {
  name: 'OrganismSidebarBuilding',
  props: {
    spaceInfo: [{}, {}],
  },
  data() {
    return {
      buildingInteractionsRef: null,
      sidebarHeaderHeight: 0,
      isMobileView: typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    }
  },
  computed: {
    isMobileMenuOpen() {
      return this.$store.state.base.mobileAvailabilityMenuOpen
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject);
    },
    disableList() {
      return this.projectFeatures?.disableMyList;
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length
    },
  },
  mounted() {
    setTimeout(() => {
      window.addEventListener('resize', this.resizeListener)
      this.sidebarHeaderHeight = document.getElementById('molecule-sidebar-header')
        ? document.getElementById('molecule-sidebar-header').clientHeight
        : 0
    }, 1000)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    handleBuildingInteractionRef(buildingInteractionsRef) {
      this.buildingInteractionsRef = buildingInteractionsRef
    },
    resizeListener(e) {
      this.isMobileView = typeof window !== 'undefined' ? window.innerWidth < 1200 : false;
      this.sidebarHeaderHeight = document.getElementById('molecule-sidebar-header')
        ? document.getElementById('molecule-sidebar-header').clientHeight
        : 0
    }
  }
}
