
export default {
  name: 'OrganismTabsTechnicalSpecsV2',
  props: {
    subTabs: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    let activeSubTab = ''
    if (this.subTabs && this.subTabs.length) {
      activeSubTab = this.subTabs[0].subTabId
    }
    return {
      activeSubTab
    }
  },
  beforeUpdate() {
    if (this.subTabs && this.subTabs.length && !this.activeSubTab) {
      this.activeSubTab = this.subTabs[0].subTabId
    }
  },
  methods: {
    handleTabChange(subTabId) {
      this.activeSubTab = subTabId
    }
  }
}
