
import requestOfferConstants from '~/store/requestOffer/-constants'
import modalConstants from "~/store/modal/-constants";
import {isMobile, isTablet} from "~/helpers/mobile/DeviceType";

export default {
  name: 'OrganismSelectedSpaceMobile',
  props: {},
  data() {
    return {
      isMobileView: typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    }
  },
  computed: {
    buildingStore() {
      return this.$store.state.building
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    hasSpaceSelected() {
      return !!this.buildingStore.space.spaceData
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject);
    },
    disableList() {
      return this.projectFeatures?.disableMyList;
    },
    selectedSpace() {
      const space = { ...this.buildingStore.space.spaceData }
      const building = this.buildings.find((b) => b.id === space.building_id)
      const floor = this.floors.find((f) => f.id === space.floor_id)
      space.building = building
      space.floor = floor
      return space
    },
    showMobileSelectedSpace() {
      if (typeof window === 'undefined') return false
      return window.innerWidth <= 1200 && this.hasSpaceSelected
    },
    isEmbedPath() {
      return this.$route.path.includes('/embed')
    },
    isEmbedV2Path() {
      return this.$route.path.includes('/embed/v2');
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : this.$t("sqm")
    },
    spacePeople() {
      if (this.unitOfMeasure === 1) {
        const unitSQM = this.selectedSpace.sqm / 10.763910417
        return Math.floor(unitSQM / 8)
      }
      return Math.floor(this.selectedSpace.sqm / 8)
    },
    showGeneratedNumberOfPeople() {
      const commonFeatures =
        this.$store?.state?.base?.meta?.project?.sections?.find(
          (section) => section.type === 'Building'
        ) || {}
      if ('generatedNumberOfPeople' in commonFeatures) {
        return commonFeatures.generatedNumberOfPeople
      } else {
        return true
      }
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
  },
  mounted() {
    let self = this
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    closeSelectedSpace() {
      this.resetView();
    },
    resetView() {
      const manager = this.engine3d.getClientManager()
      if (manager) {
        manager.hideHighlight(true)
        if (isMobile() || isTablet()) {
          manager.showPinsByCategory('none');
          this.$store.dispatch('building/setDefaultFilterPinsMode', 'none')
        } else {
          manager.showPinsByCategory('');
          this.$store.dispatch('building/setDefaultFilterPinsMode', '')
        }
      }
      if (isMobile() || isTablet()) {
        this.$store.dispatch('building/clearSpaceData')
      }
    },
    resizeListener(e) {
      this.isMobileView = typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find((space) => space.id === spaceId)
    },
    removeSpaceFromCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'remove_space_from_list',
          payload: {
            zone: 'space',
            space
          }
        })
      }
      this.$store.dispatch(requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE), space)
    },
    addSpaceToCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'add_space_to_list',
          payload: {
            zone: 'space',
            space
          }
        })
      }
      this.$store.dispatch(requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE), space)
    },
    addSpaceToCartAndOpenRequestOffer(selectedSpace) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'building'
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        selectedSpace
      )
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer'
      })
      document.body.classList.add('disable-scroll')
    },
    exploreSpace(selectedSpace) {
      let spaceCode = selectedSpace.code
      let buildingCode = selectedSpace.building.code
      let floorCode = selectedSpace.floor.code
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'explore_space_trigger',
          payload: {
            zone: 'building',
            spaceCode,
            buildingCode,
            floorCode
          }
        })
      }
      if (!this.isEmbedPath) {
        this.$router.push({
          path: `/project/${this.activeProject}/space/${selectedSpace.id}`
        })
      } else {
        this.$router.push({
          path: `/embed/${(this.isEmbedV2Path ? 'v2/': '')}${this.activeProject}/space/${selectedSpace.id}`
        })
      }
    },
  }
}
