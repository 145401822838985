
export default {
  name: 'OrganismPinInfoBox',
  props: {
    hightlight: {
      required: false,
      type: String,
      default: ''
    },
    title: {
      required: false,
      type: String,
      default: ''
    },
    subtitle: {
      required: false,
      type: String,
      default: ''
    },
    pinData: {
      required: false,
      type: Array || null,
      default: [
        {
          highlightAnchor: 'highlightStairsExterior0',
          pinTitle: 'Exterior emergency staircase',
          pinDescription: '',
          type: ''
        }
      ]
    }
  },

  data() {
    return {}
  }
}
